import "./App.css";
import { MainPage } from "../pages/MainPage/MainPage";
import { observer } from "mobx-react-lite";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
	return (
		<div className="App">
			<AppRoutes />
		</div>
	);
}

export default observer(App);
