import { observer } from "mobx-react-lite";
import styles from "./GamePage.module.scss";
import { useNavigate } from "react-router-dom";
import { Header } from "src/modules/common/ui/Header/Header";
import { Footer } from "src/modules/common/ui/Footer/Footer";
import arrow from "src/modules/ourGames/assets/img/svg/arrow.svg";
import discord from "src/modules/ourGames/assets/img/svg/discord.svg";
import twitter from "src/modules/ourGames/assets/img/svg/twitter.svg";
import telegram from "src/modules/ourGames/assets/img/svg/telegram.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import useScrollToTop from "src/modules/common/hooks/useScrollToTop";
import { useContext } from "react";
import { Context } from "src";
import TextWithLineBreaks from "../TextWithLineBreaks/TextWithLineBreaks";

export const GamePage = observer(() => {
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const { storeGames } = useContext(Context);

	useScrollToTop();

	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<Header />
				<div className={styles.contentGames}>
					<div className={styles.title}>{storeGames.currentGame.title}</div>
					<div className={styles.games}>
						<div className={styles.left}>
							<div className={styles.image}>
								<img src={storeGames.currentGame.imageBig} alt="" />
							</div>
							{Number(windowSize.width) > 1380 ? (
								<div
									className={styles.btn}
									onClick={() => window.open(`${storeGames.currentGame.link}`)}
								>
									PLAY NOW
								</div>
							) : (
								<TextWithLineBreaks
									props={storeGames.currentGame.description}
								/>
							)}
						</div>
						<div className={styles.right}>
							<div className={styles.rightContent}>
								{Number(windowSize.width) > 1380 ? (
									<TextWithLineBreaks
										props={storeGames.currentGame.description}
									/>
								) : (

									<div
										className={styles.btn}
										onClick={() =>
											window.open(`${storeGames.currentGame.link}`)
										}
									>
										PLAY NOW
									</div>
								)}
								{Number(windowSize.width) > 1380 ? (
									<div className={styles.icons}>
										{storeGames.currentGame.telegram !== "" && (
											<img
												src={telegram}
												alt=""
												onClick={() =>
													window.open(`${storeGames.currentGame.telegram}`)
												}
											/>
										)}
										{storeGames.currentGame.discord !== "" && (
											<img
												src={discord}
												alt=""
												onClick={() =>
													window.open(`${storeGames.currentGame.discord}`)
												}
											/>
										)}
										{storeGames.currentGame.twitter !== "" && (
											<img
												src={twitter}
												alt=""
												onClick={() =>
													window.open(`${storeGames.currentGame.twitter}`)
												}
											/>
										)}
									</div>
								) : (
									<div className={styles.btnBack} onClick={() => navigate(-1)}>
										<div>GO BACK</div>
										<img src={arrow} alt="" />
									</div>
								)}
							</div>
							{Number(windowSize.width) > 1380 ? (
								<div className={styles.btnBack} onClick={() => navigate(-1)}>
									<div>GO BACK</div>
									<img src={arrow} alt="" />
								</div>
							) : (
								<div className={styles.icons}>
									{storeGames.currentGame.telegram !== "" && (
										<img
											src={telegram}
											alt=""
											onClick={() =>
												window.open(`${storeGames.currentGame.telegram}`)
											}
										/>
									)}
									{storeGames.currentGame.discord !== "" && (
										<img
											src={discord}
											alt=""
											onClick={() =>
												window.open(`${storeGames.currentGame.discord}`)
											}
										/>
									)}
									{storeGames.currentGame.twitter !== "" && (
										<img
											src={twitter}
											alt=""
											onClick={() =>
												window.open(`${storeGames.currentGame.twitter}`)
											}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
});
