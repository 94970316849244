import styles from "./Game.module.scss";
import steam from "src/modules/ourGames/assets/img/svg/steam.svg";
import discord from "src/modules/ourGames/assets/img/svg/discord.svg";
import twitter from "src/modules/ourGames/assets/img/svg/twitter.svg";
import telegram from "src/modules/ourGames/assets/img/svg/telegram.svg";
import { IGame } from "../../types/types";

export const Game: React.FC<{ game: IGame }> = ({ game }) => {
	return (
		<div className={styles.content}>
			<img src={game.image} alt="" className={styles.image} />
			<div className={styles.title}>{game.title}</div>
			<div className={styles.tags}>
				{game.tags.map((item, index) => {
					return <div className={styles.type}>{item}</div>;
				})}
			</div>
			<div className={styles.icons}>
				{game.telegram !== "" && (
					<img
						src={telegram}
						alt=""
						onClick={() => window.open(`${game.telegram}`)}
					/>
				)}
				{game.discord !== "" && (
					<img
						src={discord}
						alt=""
						onClick={() => window.open(`${game.discord}`)}
					/>
				)}
				{game.twitter !== "" && (
					<img
						src={twitter}
						alt=""
						onClick={() => window.open(`${game.twitter}`)}
					/>
				)}
				{/* <img
					src={steam}
					alt=""
					onClick={() => window.open(`${game.link}`)}
				/> */}
			</div>
		</div>
	);
};
