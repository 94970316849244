import styles from "./GamingEmpire.module.scss";
import seiTitle from "src/modules/gamingEmpire/assets/img/svg/seiTitle.svg";
import dragon from "src/modules/gamingEmpire/assets/img/png/dragon.png";

export const GamingEmpire = () => {
	return (
		<div className={styles.content}>
			<div className={styles.mainContent}>
				<div className={styles.titleBlock}>
					<div className={styles.title}>
						WE ARE BUILDING A GAMING EMPIRE TOGETHER ON
					</div>
					<div className={styles.seiBlock}>
						<img src={seiTitle} alt="" />
					</div>
				</div>
				<img src={dragon} alt="" className={styles.dragon} />
			</div>
		</div>
	);
};
