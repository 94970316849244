import styles from "./Header.module.scss";
import logo from "../../assets/img/svg/logo.svg";
import discord from "../../assets/img/svg/discord.svg";
import { ButtonJoin } from "../ButtonJoin/ButtonJoin";
import menu from "../../assets/img/svg/menu.svg";
import cross from "../../assets/img/svg/cross.svg";
import { useState } from "react";
import cl from "classnames";
import { useNavigate } from "react-router-dom";

export const Header = () => {
	const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);
	const navigate = useNavigate();

	return (
		<div
			className={cl(styles.wrapper, { [styles.mobileWrapper]: isOpenMobile })}
		>
			<div className={styles.content}>
				<div className={styles.menuBlock}>
					<img src={logo} alt="" />
					<div className={styles.menu}>
						<div
							onClick={() => {
								navigate("/");
								setTimeout(() => {
									let element = document.getElementById("gameDevelopers");
									if (element !== null)
										document
											.getElementById("gameDevelopers")!
											.scrollIntoView({ behavior: "smooth" });
								}, 1000);
							}}
						>
							ABOUT
						</div>
						<div
							onClick={() => {
								navigate("/");
								setTimeout(() => {
									let element = document.getElementById("ourGames");
									if (element !== null)
										document
											.getElementById("ourGames")!
											.scrollIntoView({ behavior: "smooth" });
								}, 1000);
							}}
						>
							GAMES
						</div>
						<div onClick={() => window.open("https://www.sei.io/")}>SEI</div>
						{/* <div>BLOG</div> */}
					</div>
				</div>
				<div className={styles.btns}>
					<ButtonJoin />
					<img src={discord} alt="" />
				</div>
				<img
					src={isOpenMobile ? cross : menu}
					alt=""
					className={styles.menuMobile}
					onClick={() => setIsOpenMobile(!isOpenMobile)}
				/>
			</div>
			{isOpenMobile && (
				<div className={styles.mobile}>
					<div className={styles.menuBlocksMobile}>
						<div
							onClick={() => {
								setIsOpenMobile(false);
								navigate("/");
								setTimeout(() => {
									let element = document.getElementById("gameDevelopers");
									if (element !== null)
										document
											.getElementById("gameDevelopers")!
											.scrollIntoView({ behavior: "smooth" });
								}, 1000);
							}}
						>
							ABOUT
						</div>
						<div
							onClick={() => {
								setIsOpenMobile(false);
								navigate("/");
								setTimeout(() => {
									let element = document.getElementById("ourGames");
									if (element !== null)
										document
											.getElementById("ourGames")!
											.scrollIntoView({ behavior: "smooth" });
								}, 1000);
							}}
						>
							GAMES
						</div>
						<div onClick={() => window.open("https://www.sei.io/")}>SEI</div>
						{/* <div>BLOG</div> */}
					</div>
					<div className={styles.btnsMobile}>
						<img
							src={discord}
							alt=""
							onClick={() => window.open("https://discord.gg/sei")}
						/>
						<ButtonJoin />
					</div>
				</div>
			)}
		</div>
	);
};
