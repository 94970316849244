import React from "react";
import { Action, BrowserHistory, Location } from "history";
import { HashRouter, Router } from "react-router-dom";

interface CustomRouterProps {
	basename?: string;
	children?: React.ReactNode;
	history: BrowserHistory;
}

interface CustomRouterState {
	action: Action;
	location: Location;
}

export const CustomBrowserRouter: React.FC<CustomRouterProps> = ({
	basename,
	children,
	history,
}: CustomRouterProps) => {
	const [state, setState] = React.useState<CustomRouterState>({
		action: history.action,
		location: history.location,
	});

	React.useLayoutEffect(() => history.listen(setState), [history]);
	return (
		<HashRouter
			basename={basename}
			children={children}
		/>
	);
};
