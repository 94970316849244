import styles from "./GamingGuild.module.scss";
import cl from "classnames";
import arrow from "src/modules/common/assets/img/svg/arrow.svg";
import torch from "src/modules/gamingGuild/assets/img/png/torch.png";
import map from "src/modules/gamingGuild/assets/img/png/map.png";
import pick from "src/modules/gamingGuild/assets/img/png/pick.png";
import { ButtonJoin } from "src/modules/common/ui/ButtonJoin/ButtonJoin";
import shadowCards from "src/modules/gamingGuild/assets/img/svg/shadowCards.svg";
import shadowCardsMobile from "src/modules/gamingGuild/assets/img/svg/shadowCardsMobile.svg";

export const GamingGuild = () => {
	return (
		<div className={styles.wrapper} id={"gamingGuild"}>
			<img src={shadowCards} alt="" className={styles.shadowCards} />
			<img
				src={shadowCardsMobile}
				alt=""
				className={styles.shadowCardsMobile}
			/>
			<div className={styles.content}>
				<div className={styles.title}>JOIN THE ULTIMATE GAMING GUILD</div>
				<div className={styles.cards}>
					<div className={cl(styles.card, styles.card1)}>
						<div className={styles.cardTitle}>LOW GAS FEES</div>
						<div className={styles.cardDescription}>
							Enhance your gaming experience with cheap transactions and endless
							scalability of SEI blockchain
						</div>
						<div className={styles.arrow}>
							<div>Learn More</div>
							<img src={arrow} alt="" />
						</div>
						<img src={torch} alt="" className={styles.torch} />
					</div>
					<div className={cl(styles.card, styles.card2)}>
						<div className={styles.cardTitle}>OUR MISSION</div>
						<div className={styles.cardDescription}>
							We aim to build a strong community of game creators and gamers and
							drive the use of blockchain technology in gaming.
						</div>
						<div className={styles.arrow}>
							<div>Learn More</div>
							<img src={arrow} alt="" />
						</div>
						<img src={map} alt="" className={styles.map} />
					</div>
					<div className={cl(styles.card, styles.card3)}>
						<div className={styles.cardTitle}>WHAT WE DO</div>
						<div className={styles.cardDescription}>
							Guild was created to help developers on SEI blockchain create,
							market and promote their games
						</div>
						<div className={styles.arrow}>
							<div>Learn More</div>
							<img src={arrow} alt="" />
						</div>
						<img src={pick} alt="" className={styles.pick} />
					</div>
				</div>
				<ButtonJoin />
			</div>
		</div>
	);
};
