import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./app/App";
import { createContext } from "react";
import StoreGames from "./modules/ourGames/store/ourGames";
import { CustomBrowserRouter } from "./app/routes/CustomBrowserRouter";
import { createBrowserHistory } from "history";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
const storeGames = new StoreGames();
interface State {
	storeGames: StoreGames;
}
export const Context = createContext<State>({
	storeGames,
});
export const history = createBrowserHistory();
root.render(
	<Context.Provider
		value={{
			storeGames,
		}}
	>
		<CustomBrowserRouter history={history}>
			<App />
		</CustomBrowserRouter>
	</Context.Provider>
);
