import { makeAutoObservable, runInAction } from "mobx";
import { IGame } from "../types/types";

export default class StoreGames {
	currentGame: IGame = JSON.parse(
		localStorage.getItem("currentGame")
			? String(localStorage.getItem("currentGame"))
			: JSON.stringify({
					image: "",
					tags: [],
					title: "",
					description: "",
					twitter: "",
					telegram: "",
					discord: "",
					link: "",
					imageBig: "",
			  })
	);

	constructor() {
		makeAutoObservable(this);
	}

	setCurrentGame = (currentGame: IGame) => {
		runInAction(() => {
			this.currentGame = currentGame;
		});
	};
}
