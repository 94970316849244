import React from "react";
import styles from "./TextWithLineBreaks.module.scss";

const TextWithLineBreaks: React.FC<{ props: string }> = ({ props }) => {
	const textWithBreaks = props.split("\n").map((text, index) => (
		<React.Fragment key={index}>
			{text}
			<br />
            <br />
		</React.Fragment>
	));

	return <div className={styles.description}>{textWithBreaks}</div>;
};

export default TextWithLineBreaks;
