import { IGame } from "../types/types";
import driftzone from "src/modules/ourGames/assets/img/png/games/1_driftzone.webp";
import driftzoneBig from "src/modules/ourGames/assets/img/png/games/1_driftzoneBig.png";
import dragonslither from "src/modules/ourGames/assets/img/png/games/2_dragonslither.webp";
import dragonslitherBig from "src/modules/ourGames/assets/img/png/games/2_dragonslitherBig.png";
import archerhunter from "src/modules/ourGames/assets/img/png/games/3_archerhunter.webp";
import archerhunterBig from "src/modules/ourGames/assets/img/png/games/3_archerhunterBig.png";
import fishwar from "src/modules/ourGames/assets/img/png/games/4_fishwar.webp";
import fishwarBig from "src/modules/ourGames/assets/img/png/games/4_fishwarBig.png";
import seiyara from "src/modules/ourGames/assets/img/png/games/5_seiyara.webp";
import seiyaraBig from "src/modules/ourGames/assets/img/png/games/5_seiyaraBig.png";
import goodgamearcade from "src/modules/ourGames/assets/img/png/games/6_goodgamearcade.webp";
import goodgamearcadeBig from "src/modules/ourGames/assets/img/png/games/6_goodgamearcadeBig.png";
import quizmatch from "src/modules/ourGames/assets/img/png/games/7_quizmatch.webp";
import quizmatchBig from "src/modules/ourGames/assets/img/png/games/7_quizmatchBig.png";
import candydreams from "src/modules/ourGames/assets/img/png/games/8_candydreams.webp";
import candydreamsBig from "src/modules/ourGames/assets/img/png/games/8_candydreamsBig.png";
import memepet from "src/modules/ourGames/assets/img/png/games/9_memepet.webp";
import memepetBig from "src/modules/ourGames/assets/img/png/games/9_memepetBig.png";
import cowtopia from "src/modules/ourGames/assets/img/png/games/10_cowtopia.webp";
import cowtopiaBig from "src/modules/ourGames/assets/img/png/games/10_cowtopiaBig.png";
import majyotreasure from "src/modules/ourGames/assets/img/png/games/11_majyotreasure.webp";
import majyotreasureBig from "src/modules/ourGames/assets/img/png/games/11_majyotreasureBig.png";
import anome from "src/modules/ourGames/assets/img/png/games/12_anome.webp";
import anomeBig from "src/modules/ourGames/assets/img/png/games/12_anomeBig.png";
import dragonninja from "src/modules/ourGames/assets/img/png/games/13_dragonninja.webp";
import dragonninjaBig from "src/modules/ourGames/assets/img/png/games/13_dragonninjaBig.webp";
import catvsmonster from "src/modules/ourGames/assets/img/png/games/14_catvsmonster.webp";
import catvsmonsterBig from "src/modules/ourGames/assets/img/png/games/14_catvsmonsterBig.png";
import piratopia from "src/modules/ourGames/assets/img/png/games/15_piratopia.webp";
import piratopiaBig from "src/modules/ourGames/assets/img/png/games/15_piratopiaBig.webp";
import catton from "src/modules/ourGames/assets/img/png/games/16_catton.webp";
import cattonBig from "src/modules/ourGames/assets/img/png/games/16_cattonBig.png";
import spincity from "src/modules/ourGames/assets/img/png/games/17_spincityf.webp";
import spincityBig from "src/modules/ourGames/assets/img/png/games/17_spincityBig.webp";
import meowkingdom from "src/modules/ourGames/assets/img/png/games/18_meowkingdom.webp";
import meowkingdomBig from "src/modules/ourGames/assets/img/png/games/18_meowkingdomBig.png";
import pixudi from "src/modules/ourGames/assets/img/png/games/19_pixudi.webp";
import pixudiBig from "src/modules/ourGames/assets/img/png/games/19_pixudiBig.webp";
import starsymphony from "src/modules/ourGames/assets/img/png/games/20_starsymphony.webp";
import starsymphonyBig from "src/modules/ourGames/assets/img/png/games/20_starsymphonyBig.webp";
import europefantasyleague from "src/modules/ourGames/assets/img/png/games/21_europefantasyleague.webp";
import europefantasyleagueBig from "src/modules/ourGames/assets/img/png/games/21_europefantasyleagueBig.png";
import metaarena from "src/modules/ourGames/assets/img/png/games/22_metaarena.webp";
import metaarenaBig from "src/modules/ourGames/assets/img/png/games/22_metaarenaBig.png";
import shadow from "src/modules/ourGames/assets/img/png/games/23_shadow.png";
import shadowBig from "src/modules/ourGames/assets/img/png/games/23_shadowBig.jpg";
import heroesOfHoldem from "src/modules/ourGames/assets/img/png/games/24_heroesOfHoldem.png";
import heroesOfHoldemBig from "src/modules/ourGames/assets/img/png/games/24_heroesOfHoldemBig.png";
import wcoin from "src/modules/ourGames/assets/img/png/games/25_wcoin.jpg";
import wcoinBig from "src/modules/ourGames/assets/img/png/games/25_wcoinBig.jpg";
import khosmium from "src/modules/ourGames/assets/img/png/games/26_khosmium.jpg";
import khosmiumBig from "src/modules/ourGames/assets/img/png/games/26_khosmiumBig.jpg";
import duckgang from "src/modules/ourGames/assets/img/png/games/27_duckgang.jpg";
import duckgangBig from "src/modules/ourGames/assets/img/png/games/27_duckgangBig.png";
import infinitygames from "src/modules/ourGames/assets/img/png/games/28_infinitygames.jpg";
import infinitygamesBig from "src/modules/ourGames/assets/img/png/games/28_inifinitygamesBig.png";
import botwars from "src/modules/ourGames/assets/img/png/games/29_botwars.jpg";
import botwarsBig from "src/modules/ourGames/assets/img/png/games/29_botwarsBig.jpg";
import operationsafeplace from "src/modules/ourGames/assets/img/png/games/30_operationsafeplace.png";
import operationsafeplaceBig from "src/modules/ourGames/assets/img/png/games/30_operationsafeplaceBig.png";
import landverse from "src/modules/ourGames/assets/img/png/games/31_landverse.png";
import landverseBig from "src/modules/ourGames/assets/img/png/games/31_landverseBig.avif";
import moneyshot from "src/modules/ourGames/assets/img/png/games/32_moneyshot.png";
import moneyshotBig from "src/modules/ourGames/assets/img/png/games/32_moneyshotBig.png";
import sevian from "src/modules/ourGames/assets/img/png/games/33_sevian.png";
import sevianBig from "src/modules/ourGames/assets/img/png/games/33_sevianBig.png";
import bombpixel from "src/modules/ourGames/assets/img/png/games/34_bombpixel.jpg";
import bombpixelBig from "src/modules/ourGames/assets/img/png/games/34_bombpixelBig.jpg";
import fusyfox from "src/modules/ourGames/assets/img/png/games/35_fusyfox.jpg";
import fusyfoxBig from "src/modules/ourGames/assets/img/png/games/35_fusyfoxBig.jpg";
import spacemavericks from "src/modules/ourGames/assets/img/png/games/36_spacemavericks.png";
import spacemavericksBig from "src/modules/ourGames/assets/img/png/games/36_spacemavericksBig.png";
import gptwars from "src/modules/ourGames/assets/img/png/games/37_gptwars.jpg";
import gptwarsBig from "src/modules/ourGames/assets/img/png/games/37_gptwarsBig.png";
import worldofdupians from "src/modules/ourGames/assets/img/png/games/38_worldofdupians.jpg";
import worldofdupiansBig from "src/modules/ourGames/assets/img/png/games/38_worldofdupiansBig.webp";
import memepixelquest from "src/modules/ourGames/assets/img/png/games/39_memepixelquest.jpg";
import memepixelquestBig from "src/modules/ourGames/assets/img/png/games/39_memepixelquestBig.jpg";
import sunnysideacres from "src/modules/ourGames/assets/img/png/games/40_sunnysideacres.png";
import sunnysideacresBig from "src/modules/ourGames/assets/img/png/games/40_sunnysideacresBig.png";

export const games: IGame[] = [
	{
		image: pixudi,
		imageBig: pixudiBig,
		tags: ["NFT", "Metaverse"],
		title: "Pixudi",
		description:
			"Pixudi is a race board game built on the blockchain technology. Players compete with each other to collect more treasures than others by overcoming obstacles that come their way and completing missions. While the concept of the game is pretty old, the technologies used to implement it are cutting-edge:\nAll characters and multiple items are NFTs minted on our partner blockchains;\nAll NFTs can be improved and evolved\nMany mechanics implemented on different networks\nIn Pixudi, traditional mechanics of a board game intertwine with a customized game economy, animation and quick mini games. Playing on a board is fun, but you can also play mini games to get advantage of your opponents, complete quests to get resources, interact with your pet, boost your character, and many other things. Once in every few weeks, a new seasonal event is released, bringing new story, board, characters, pets, items, quests and other stuff.\nPixudi combines 2 business models: Free2Play and Play2Collect. It means that players don't need to pay any money to start playing but they can purchase Mystery Packs to enhance their gaming experience: get nicer characters and boost them, receive items, etc. Important note: there is no Pay-to-Win mechanics in Pixudi. No matter how many Mystery Packs you buy, victory is not guaranteed. All characters and items can be sold on a marketplace.",
		twitter: "https://x.com/thepixudi",
		telegram: "https://t.me/pixudi",
		discord: "https://discord.gg/W4QGHh9FRX",
		link: "https://game.pixudi.com",
	},
	{
		image: shadow,
		imageBig: shadowBig,
		tags: ["Games"],
		title: "Shadowgate Mysteries",
		description:
			"Embark on an extraordinary journey with Shadowgate Mysteries, a pioneering MMORPG that elevates beloved classics like *Stardew Valley* and *Disney Valley* into the vibrant realm of Web3 gaming \n🌍. Inherit a tavern in a fantastical world where your decisions impact both the local community and the broader ecosystem \n🌱. Engage in farming, animal husbandry, and crafting to serve up delights that enchant diverse characters, ensuring every harvest and brew enriches your legacy \n🍻. Crafted by a team that pioneered the first Web 2.5 game, which already attracts 170,000 players monthly, Shadowgate Mysteries is set to redefine gaming norms.",
		twitter: "https://x.com/shadowgategame",
		telegram: "",
		discord: "https://discord.gg/shadowmysteries",
		link: "https://shadowgate.quest/",
	},
	{
		image: driftzone,
		imageBig: driftzoneBig,
		tags: ["Play to Earn", "DeFi"],
		title: "Drift Zone",
		description:
			"Drift Zone integrates distinctive artistry with advanced DeFi mechanics and Gacha, powered by SeiNetwork.\nGet Cubes and collect unique Drifters, empowering players with true ownership of their in-game assets. The game merges the thrill of random multipliers with strategic battles within a balanced token economy designed for long-term sustainability.  Every element is crafted to be both immersive and financially rewarding, pushing the boundaries of web3 gaming innovation.",
		twitter: "https://x.com/DriftZoneGG",
		telegram: "https://x.com/DriftZoneGG",
		discord: "",
		link: "https://www.driftzone.io/",
	},
	{
		image: dragonslither,
		imageBig: dragonslitherBig,
		title: "Dragon Slither",
		description: `🐲 Step into the future of gaming with Dragon Slither – the leading Web3 GameFi survival platform integrated with Telegram and powered by the SEI Blockchain. Dragon Slither offers a unique blend of entertainment and profit-making opportunities, allowing players to securely own digital assets and earn by accumulating DRAGONS tokens.\n🐉What sets Dragon Slither apart? Dragon Slither provides an unmatched gaming experience through its real-time PvP combat available on both mobile and PC. It also features the exciting PvE 'Play to Airdrop' mode, where players can passively 'mine' tokens and mint valuable NFTs using Dragon Eggs. Whether you're battling in custom PvP rooms or hatching eggs for rewards, Dragon Slither ensures an immersive, rewarding experience.\n🐉Key Milestones Since its Beta launch in Q3 2024, Dragon Slither has achieved significant growth, surpassing 400,000 users and 365,000 monthly active users. With a rapidly expanding community of over 250,000 members across Telegram and Twitter, Dragon Slither is quickly becoming a leading force in the GameFi space on SEI.\n🐉Dragon Slither's Offerings: - Real-time PvP & Custom PvP Rooms: Compete in high-stakes PvP battles with friends or random players.\n- Egg Hatching: Mine $vDRAGONS tokens, unlock exclusive NFT skins, and earn SEI rewards up to 300% of the egg's value. - Diverse NFT Item Shop: Upgrade your abilities and style by purchasing rare and legendary NFTs that reflect your achievements.\n- Referral & Affiliate Programs: Invite friends to Dragon Slither and earn $vDRAGONS and SEI tokens as rewards for you and your friends.`,
		tags: ["Play to Earn"],
		twitter: "https://x.com/Dragon_Slither",
		telegram: "https://t.me/DragonSlither_chat",
		discord: "https://discord.gg/MGQPD6yXGW",
		link: "https://dragonslither.com/",
	},
	{
		image: archerhunter,
		imageBig: archerhunterBig,
		tags: ["Play to Earn", "DeFi"],
		title: "Archer Hunter",
		description:
			"💥 Archer Hunter is an RPG game | Original Web3 Gaming IP published by Nika Labs, boasting millions of downloads on the App Store. Powered by SEI Network and Allora Network, Archer Hunter aims to become the first blockchain game with a proprietary IP publisher 🔴💨\n🏹 Archer Hunter offers thrilling adventures, where you are surrounded by hordes of monsters with one objective: to fight and conquer. Rely on your skills, master new abilities, and defeat enemies. Starting as a humble soldier, you’ll forge your path to becoming a master through intense battles! ⚔️👀",
		twitter: "https://x.com/archerhunter_HQ",
		telegram: "https://t.me/archerhunter_games",
		discord: "https://discord.com/invite/R2DfvVXWzV",
		link: "https://archerhunter.io/",
	},
	{
		image: fishwar,
		imageBig: fishwarBig,
		tags: ["NFT", "Play to Earn"],
		title: "FishWar",
		description:
			"Key Features:\n*️Free-to-Play\n*️PVE & PVP Modes\n*️Spin & Egg Hunt\n*️Exclusive NFT Items & In-game items\n*️Affiliate Program\nWhy Play FishWar?\n*️Egg Hunt & Feed-to-Earn Model: Hatch rare fish from eggs, feed them daily, and maximize your earnings with up to 770% returns.\n*️VIP & Referral System: The more you play and invite, the higher your rank and commissions. Earn extra rewards based on your level and invite others to the battle. Earn up to $60,000 USDT by Inviting friends and boost your earnings.\n*️Community-Oriented Growth: With over 500,000 players and growing, join a vibrant and engaging community that's shaping the future of GameFi.\n*️Leadership from the Best: We’re proud to have Eljaboom, Forbes 40 under 40 and Guinness World Record holder, as an advisor guiding our growth.\nIn-Game Rewards:\n*️Egg Collection: Discover 10 types of limited-edition eggs, hatch rare fish, and earn big! From Beginner to Godlike, every egg offers exciting rewards.\n*️PVP & PVE Battles: Engage in real-time PVP battles, challenge global players, and climb to the top of the leaderboard.\n*️Affiliate System: Our referral program allows you to earn 10% commissions from your referrals' earnings, scaling as you rise through the ranks.\n*️Spin & Win Feature: Spin the wheel for a chance to win $FishW, fiat, and exclusive in-game bonuses.",
		twitter: "https://x.com/FishWarOfficial",
		telegram: "https://t.me/Fishwar_io",
		discord: "https://discord.com/invite/QCQacQbmSM",
		link: "https://fishwar.io/",
	},
	{
		image: seiyara,
		imageBig: seiyaraBig,
		tags: ["Play to Earn"],
		title: "SEIYARA",
		description:
			"SEIYARA is an exciting farm simulation game where players enter the vibrant world of Seiyan to grow, harvest, and create valuable products.\n✔️How does SEIYARA work?\n🚀 Plan your crop on Land\n🚀 Auto harvest get Gold and Lucky chest\n🚀 View ads everyday get more rewards\n🚀 Complete quest and Archivement mission\n🚀 Top-up get more Diamonds and Dragon Ball Chest\n🚀 Upgrade Lucky chest and Headquarter\n🚀 Get Dragon Ball for more Diamonds and NFTs  ",
		twitter: "https://x.com/seiyara_io",
		telegram: "https://t.me/seiyara_news",
		discord: "",
		link: "https://seiyara.io/",
	},
	{
		image: goodgamearcade,
		imageBig: goodgamearcadeBig,
		tags: ["Staking", "Play to Earn"],
		title: "Good Game Arcade",
		description:
			"Good Game Arcade is a groundbreaking gaming platform that offers players not only entertainment but also a stake in the company. Every player is an owner, with all revenue generated on the platform shared back into the Ad-venture V1 pool, providing a unique opportunity to earn rewards. With a variety of engaging games, including the popular Pocket Quest, Good Game Arcade creates an interactive and rewarding ecosystem where players can enjoy gaming while benefiting from their contributions to the community.",
		twitter: "https://x.com/XGoodGameArcade",
		telegram: "https://t.me/GoodGameArcade",
		discord: "",
		link: "https://ggarcade.io",
	},
	{
		image: quizmatch,
		imageBig: quizmatchBig,
		tags: ["NFT", "Play to Earn"],
		title: "Quizmatch",
		description:
			"QuizMatch is an on-chain gaming platform that combines the thrill of quizzes with blockchain technology. Players can join tournaments, invite friends for head-to-head PVP matches, and compete for exciting prizes.\nThe game offers a range of gamification features, including leaderboards, daily rewards, and achievements to keep players engaged. By integrating NFTs, QuizMatch allows users to gain special advantages during gameplay, enhancing their strategy and competitiveness. All game scores and leaderboard data are stored on-chain, ensuring transparency and fairness.\nWith global integration through top telecom operators, QuizMatch offers a seamless and competitive gaming experience to users worldwide.",
		twitter: "https://x.com/playquizmatch",
		telegram: "",
		discord: "",
		link: "https://play.quizmatch.games",
	},
	{
		image: candydreams,
		imageBig: candydreamsBig,
		tags: ["Play to Earn"],
		title: "Candy Dreams",
		description:
			"First-ever match-3 bot game powered by @SeiNetwork, join to maximize mining rewards!",
		twitter: "https://x.com/CandyDreams_M",
		telegram: "https://t.me/CandyDreamsOFL",
		discord: "",
		link: "https://www.mokoko.vip/",
	},
	{
		image: memepet,
		imageBig: memepetBig,
		tags: ["Play to Earn"],
		title: "MemePet",
		description:
			"MemePet is a next-gen social game powered by @SeiNetwork, blending meme-inspired fun with immersive gameplay. Players run pet shops, collect and breed cute pets, and grow their business to earn $Memepet.\nAccessible via web and major social platforms, MemePet attracts users with engaging gameplay, meme culture vibes, and interactive social features.\nWhy MemePet\n-Engaging Gameplay\nMemePet offers an immersive experience where players manage pet shops, complete quests, and breed pets. Its dynamic challenges keep the game fun and emotionally engaging.\n-Meme Vibe\nBy tapping into meme culture, MemePet has strong viral potential and wide appeal. Its humorous, meme-inspired elements attract a diverse audience, boosting user engagement and retention.\n-Enhanced Social Features\nPlayers can interact via major social platforms to visit each other’s shops, exchange gifts, and trade pets (coming soon). These social connections foster a strong community and long-term engagement.",
		twitter: "https://x.com/memepet_game",
		telegram: "https://t.me/memepet_tg",
		discord: "",
		link: "https://memepet.io/",
	},
	{
		image: cowtopia,
		imageBig: cowtopiaBig,
		tags: ["NFT", "Play to Earn"],
		title: "Cowtopia",
		description:
			"Cowtopia is an innovative farming game on the Sei network blockchain, blending traditional farming simulation with NFT collectibles and competitive gameplay. Players manage farms, collect milk, and complete tasks while building a collection of legendary COW NFTs. These NFTs are crucial for participating in competitions to win LAND NFTs, which enable crystal mining. Crystals can be exchanged for $COWT, the game's native token. The game offers a unique experience combining resource management, strategy, and competition. Players can invite friends, upgrade farms, and strive to become top farmers in this digital agricultural world. Cowtopia appeals to both casual gamers and competitive players, offering a rich, engaging experience in the blockchain gaming space.",
		twitter: "https://x.com/Cowtopiagame",
		telegram: "https://t.me/Cowtopia_Channel",
		discord: "",
		link: "https://cowtopia.tonfarmer.com",
	},
	{
		image: majyotreasure,
		imageBig: majyotreasureBig,
		tags: ["Play to Earn"],
		title: "Majyo Treasure",
		description: "An idle RPG mini game on Telegram and powered by SEI",
		twitter: "https://x.com/MajyoGame",
		telegram: "https://t.me/MajyoTreasureChannel",
		discord: "",
		link: "https://www.mokoko.vip/majyo-treasure",
	},
	{
		image: anome,
		imageBig: anomeBig,
		tags: ["NFT", "DeFi"],
		title: "Anome",
		description:
			"Anome is a groundbreaking platform for the issuance, gaming, and lending of refundable asset, powered by @SeiNetwork\nAnome creates a seamless ecosystem that merges DeFi, NFTFi, and GameFi, distinguishing itself with innovative refundable asset issuance, rapid game deployment, exceptional user engagement, and higher Loan-to-Value ratios with non-liquidation lending services.\nAnome’s Trading Card Game (TCG) is the world’s first AI-driven, fully on-chain TCG supporting ERC404 assets, setting a new standard in the web3 gaming industry.\nCritical Features of Anome:\n(1) All issued assets can be refunded at original value.\n(2) All issued assets support non-liquidation collateral lending for USDT.\n(3) Playing a match takes about 1-5 minutes, and winner can earn an 80% return.",
		twitter: "https://x.com/Anome_Official",
		telegram: "https://t.me/Anome_DAO",
		discord: "https://discord.gg/9BX6qBsBbu",
		link: "https://anome.xyz/",
	},
	{
		image: dragonninja,
		imageBig: dragonninjaBig,
		tags: ["Play to Earn"],
		title: "Dragon Ninja",
		description:
			"Dragon Ninja - The First AI-driven Roguelike Game in Web3, Powered by SeiNetwork\nKey Products:\n➭ Dragon Ninja Mini-Game on Telegram: Engage, play, and earn rewards.\n➭ Battle Royale Player vs Player Mode: Join this exciting gambling to win a 7x reward.\n➭ Special Character NFTs: Own special SocialFi features and Earn a stable share\n➭ AI Generation: AI generates numerous levels based on players' habits.",
		twitter: "https://x.com/dragonninja_io",
		telegram: "https://t.me/dragon_ninja_io",
		discord: "",
		link: "https://dragon-ninja.io/",
	},
	{
		image: catvsmonster,
		imageBig: catvsmonsterBig,
		tags: ["Play to Earn"],
		title: "Cat vs Monster",
		description:
			"Cat VS Monsters is the Idle RPG. Dark times have fallen upon the peaceful village as vile monsters and slimes threaten to destroy everything in their path. As the heroic hunter, you must embark on an epic adventure to defeat the invading forces and save your home.",
		twitter: "https://x.com/catvsmonsters",
		telegram: "https://t.me/catvsmonsters_ann",
		discord: "",
		link: "https://catvsmonster.io",
	},
	{
		image: piratopia,
		imageBig: piratopiaBig,
		tags: ["NFT", "Play to Earn"],
		title: "Piratopia",
		description:
			"Piratopia: Pioneering Web3 Gaming on Telegram\nPiratopia is set to redefine gaming on Telegram with its innovative Play-to-Earn (P2E) NFT model. By merging engaging gameplay with true digital ownership and a dynamic token economy, we're tapping into Telegram's vast user base to set new standards in Web3 gaming.\nOur Team:\nLed by experienced developers and blockchain experts, our team has launched successful games like Stickman Shadow Battle and Zombie War Idle Defense, with millions of downloads.\nFeatures:\n- Customizable Islands: Build and personalize your pirate haven with strategic upgrades.\n- Resource Gathering: Mine, raid, and complete quests to expand your empire.\n- NFT Marketplace: Trade unique pirate-themed NFTs to enhance gameplay and investment opportunities.\n- Community Governance: Shape the game's future through voting and earn rewards for your involvement.",
		twitter: "https://x.com/thepiratopia",
		telegram: "https://t.me/Piratopia",
		discord: "",
		link: "https://piratopia.xyz/",
	},
	{
		image: catton,
		imageBig: cattonBig,
		tags: ["NFT", "Play to Earn"],
		title: "Catton",
		description:
			"😀 Catton, where we introduce you to the Cattonverse—a unique blend of gaming and DeFi on Telegram.\nCatton won KuCoin prizes of the TON Open League Hackathon! Granted by TON foundation and powerd by Sei foundation.\nWe were pleasantly surprised by the overwhelming reception from the community:\n✅ Reached 350k social users\n🎮 380k in-game users\n💎 76k on-chain transactions",
		twitter: "https://twitter.com/Cattontw",
		telegram: "https://t.me/cattonAnn",
		discord: "",
		link: "https://catton.tech",
	},
	{
		image: spincity,
		imageBig: spincityBig,
		tags: ["Play to Earn", "DeFi"],
		title: "SpinCity",
		description:
			"SpinCity is a gamified social platform on the SEI blockchain, rewarding players through interactive gameplay, daily tasks, and quests. Players can earn different rewards, complete challenges, and compete on weekly and monthly leaderboards to boost their earnings. With opportunities to invite friends, gain multiple tokens, and increase mining efficiency, SpinCity offers a dynamic space for community engagement and growth. Designed for both fun and rewards, SpinCity lets users enjoy gaming while building their earnings in a fair and transparent ecosystem.\nIn SpinCity, there’s always something thrilling to explore and achieve! Here’s a quick look at the opportunities waiting for you:\nMining: Collect gold coins for free every second, building your in-game resources as you play.\nCompete on Leaderboards: Boost your referral count by inviting friends and climb the daily, weekly, and monthly leaderboards for exclusive prizes and recognition.\nComplete Tasks: Take on daily and special tasks to earn rewards and boosts that enhance your gameplay.\nPlay Games: Dive into a variety of games to win rewards, complete quests, and achieve new milestones.\nBuild Hashpower: Strengthen your in-game profile by tackling daily challenges, playing regularly, and inviting friends, unlocking access to exclusive events and rewards.\nInvite Friends: Bring your friends into SpinCity to increase your rewards. The more friends you invite, the more tokens you’ll farm, climbing the leaderboard for greater rewards and recognition.\nEarn Stakeholder Benefits: As an active player, your hashpower and achievements could make you eligible for future token airdrops, giving you a stake in SpinCity’s growth.",
		twitter: "",
		telegram: "https://t.me/SpinCityAI_Bot/SpinCity",
		discord: "",
		link: "https://spincity.ai",
	},
	{
		image: meowkingdom,
		imageBig: meowkingdomBig,
		tags: ["Games"],
		title: "Meow Kingdom",
		description:
			"Meow Kingdom is an innovative game available on Telegram that seamlessly integrates village-building mechanics, slot machine elements, and robust social interaction features.",
		twitter: "https://x.com/meowkingdom_io",
		telegram: "https://t.me/meowkingdom_channel",
		discord: "",
		link: "https://meowkingdom.io/",
	},

	{
		image: starsymphony,
		imageBig: starsymphonyBig,
		tags: ["NFT", "Play to Earn"],
		title: "Star Symphony",
		description:
			"Star Symphony: Groundbreaking NFT Game reshaping global gaming, art, and music experiences.\n- First On Chain Rhythm Battler.\n- Platform unites players and creators, transcending traditional gaming and music boundaries.\n- Rhythm Battler: First product combining elements from Guitar Hero, Mario Kart, and Genshin Impact.\n- Catering to diverse preferences: Casual gaming, Competitive gaming, and Party gaming.\n- Key Earning Mechanisms: Play & Earn, Rep & Earn, and Hold & Earn.\n- Play & Earn: Accumulate tokens by completing in-game quests, more playtime equals more tokens.\n- Rep & Earn: Promote Star Symphony on social media to boost exposure and earn tokens.\n- Hold & Earn (Elder Pass): NFT holders get multipliers based on the quantity of Elder Passes held.\n- Star Symphony empowers gamers, creators (music producers, artists, developers) in collaborative co-creation of an intellectual property (IP).",
		twitter: "",
		telegram: "",
		discord: "",
		link: "https://www.starsymphony.io",
	},
	{
		image: europefantasyleague,
		imageBig: europefantasyleagueBig,
		tags: ["NFT", "Farming"],
		title: "Europe Fantasy League",
		description:
			"Europe Football Fantasy(EUFT), a revolutionary platform designed for soccer fans around the world. This game uses a fantasy soccer theme to gamify token earning, making the experience both fun and rewarding. Users can earn tokens by participating in daily missions and inviting friends, which allows them to acquire team flags essential for token minting.\nThe amount of tokens you mint daily is influenced by the number of flags you hold and the performance of your chosen teams. Team wins and scores directly impact your token minting rate, boosting your earnings. These tokens can then be used to trade for new season team flags from major European leagues like the EPL, Bundesliga, and La Liga.\nEurope Football Fantasy offers a simple yet powerful token mining platform, creating a unique space where soccer enthusiasts can turn their passion into tangible rewards.",
		twitter: "https://x.com/EUFTonXAI",
		telegram: "https://euft.io",
		discord: "https://discord.gg/gbbvM3bYQU",
		link: "https://euft.io",
	},
	{
		image: metaarena,
		imageBig: metaarenaBig,
		tags: ["NFT", "DeFi"],
		title: "MetaArena",
		description:
			"MetaArena is a cloud-based Web3 game engine and the first CollabSphere developer ecosystem, offering a one-stop platform for game developers, content creators, and players to build, publish, and monetize.",
		twitter: "https://x.com/MetaArenaGaming",
		telegram: "https://t.me/MetaArena_Official",
		discord: "",
		link: "https://metaarena.org/",
	},
	{
		image: heroesOfHoldem,
		imageBig: heroesOfHoldemBig,
		tags: ["Trading Card Game"],
		title: "Heroes of Holdem",
		description:
			"Enefty Gaming has created a completely unique and addictive take on Poker by adding collectible card game mechanics into traditional Texas Holdem. Utilizing a self-sustaining Play & Earn economy where players can monetize their gameplay using the native tokens, while not risking loss of assets. This integrates both serious and casual poker players into the game like no other version of poker ever has before.",
		twitter: "https://x.com/ENEFTY_GAMING",
		telegram: "",
		discord: "",
		link: "https://www.heroesofholdem.com",
	},
	// {
	// 	image: "",
	// 	imageBig: "",
	// 	tags: ['Mini-Game Platform'],
	// 	title: "Good Game Arcade",
	// 	description:
	// 		"GoodGame Arcade (previously Greedy Goblin) is an innovative platform that bridges Web2 and Web3 gaming with integrated advertising solutions. GGA unifies game developers, advertisers, and players into a comprehensive ecosystem where gaming becomes a source of real rewards and revenue.\nKey Highlights:\n- Over 1M on-chain users and 2M tx within 3 months of launch\n- Powered by Sei Network\n- Collaboration with tier 1 partners such as Bitget wallet, OKX wallet, Safepal, and Gate Web3 Wallet\nRecent Updates:\n- Launch of Pocket Quest\n- Tokenomics update\n- TGE took place on Nov. 2nd\n- Players can stake their GGA to earn rewards in our unique Ad-venture pool v1",
	// 	twitter: "https://x.com/XGreedyGoblin",
	// 	telegram: "https://t.me/greedygoblingame",
	// 	discord: "",
	// 	link: "https://www.greedygoblin.store",
	// },
	{
		image: wcoin,
		imageBig: wcoinBig,
		tags: ["Casino Mix Games"],
		title: "WCoinGame",
		description:
			"WcoinGame is dedicated to becoming the leading casino gaming brand on Telegram, offering fair, secure, and fun gaming services. Our game hall features the most popular casino games from around the world, including but not limited to slots and poker. We provide diverse earning opportunities, high-yield mechanisms, and uniquely designed games.",
		twitter: "https://x.com/whatsgamesio",
		telegram: "https://t.me/WhatsgamesChannel",
		discord: "",
		link: "https://t.me/wcoin",
	},
	{
		image: khosmium,
		imageBig: khosmiumBig,
		tags: ["MOBA"],
		title: "Khosmium",
		description:
			"Khosmium is a free-to-play game which our open beta is scheduled to launch in Q4 2024.\nOur current priority is the MOBA, which will be available exclusively on PC. This game is designed to offer players a dynamic and engaging competitive experience.\nIn addition to the PC MOBA, we are also working on mobile games that will be integrated into a larger, connected gaming ecosystem. Our goal is to develop several games with cross-platform interoperability, allowing players to benefit from shared assets and experiences across different titles.\nWe’d love to explore opportunities for partnerships and cross-promotion to help our communities grow together. Let’s connect and find ways to mutually support and expand our audiences!",
		twitter: "https://x.com/khosmium",
		telegram: "https://t.me/khosmium",
		discord: "https://discord.gg/khosmium-948855802993705021",
		link: "https://khosmium.com/",
	},
	{
		image: duckgang,
		imageBig: duckgangBig,
		tags: ["Hyper Casual Game"],
		title: "Duckgang",
		description:
			"DuckGang is a gamified DeFi experience built natively on Sei V2. Unlike traditional DeFi where majority of the yield is dictated by the protocol, DuckGang introduces a revolutionary mechanism where users stake assets, and play a series of hyper-casual games to boost their APY. The better you are in the game, the higher APYs you can unlock!\nCombined with the DuckGang NFTs that give access to wallet boosts and in-game actions, on top of wallet generation through social media and fiat on-ramp, DuckGang aims to become the go-to place for both unexperienced Web2 users and Web3 veterans to get into DeFi, and quickly onboard the Sei ecosystem.",
		twitter: "https://x.com/duckgang_com",
		telegram: "http://t.me/duckgangofficial",
		discord: "",
		link: "https://duckgang.com",
	},
	{
		image: infinitygames,
		imageBig: infinitygamesBig,
		tags: ["Auto Chess"],
		title: "Infinity Games",
		description:
			"Infinity Games is revolutionizing blockchain gaming with interoperable ecosystems. Their flagship game, Infinity Heroes, is a strategic card collection autochess game where players build powerful decks of six unique units and one hero, upgrading them to compete in automatic battles.\nThey’ve developed a blockchain protocol that allows interoperability of gaming assets, enabling players to use their NFTs across multiple compatible games within the Infinity Games ecosystem. This ensures that assets from one game can seamlessly integrate and be utilized in another, enhancing the overall gaming experience and value for players.\nTheir next step involves developing a platform for interoperability on the application layer, allowing characters to maintain their appearance and abilities across different games. This fosters a cohesive gaming ecosystem where studios benefit from shared resources, logistical support through grants, and an established user base.",
		twitter: "https://x.com/infygamestech",
		telegram: "https://t.me/infinity_games_blockchain",
		discord: "https://discord.com/invite/vuvZc5Fvq4",
		link: "https://infinitygames.tech/",
	},
	{
		image: botwars,
		imageBig: botwarsBig,
		tags: ["MMORPG"],
		title: "Botwars Ascendance (DevCo)",
		description:
			"Botwars Ascendance has strong on-chain application with NFTs and Tokens mintable on SEI.\nNFTs will be in the form of motherships (spaceships) for battle, modules for upgrading and small vessels for farming of resources which will all be required by players to advance in the game.\nTokens will be used alongside modules (NFTs) to upgrade the motherships (NFTs), purchase of resources and crafting of new materials.",
		twitter: "https://x.com/BotwarsGameplay",
		telegram: "https://t.me/+emb_XdBFsfYwOGZk",
		discord: "https://discord.gg/3swz3ZGrGq ",
		link: "https://www.botwarsascendance.com/",
	},
	{
		image: operationsafeplace,
		imageBig: operationsafeplaceBig,
		tags: ["Tower Defense"],
		title: "Operation Safe Place",
		description:
			"Operation Safe Place Tower Defense (OSPTD), features NFT-based gameplay, combining real-world social impact and immersive gaming. Players can own, trade, and use NFTs across various game modes, aiming to bridge Web3 functionality with traditional gaming experiences.",
		twitter: "https://x.com/OpSafePlace",
		telegram: "",
		discord: "https://discord.gg/operation-safe-place-td",
		link: "http://home.ospgame.com",
	},
	{
		image: landverse,
		imageBig: landverseBig,
		tags: ["Match 3"],
		title: "Landverse",
		description:
			"Landverse is an immersive open-world game based around farming and collecting, slowly progressing your way up to become the greatest landlord of all time!",
		twitter: "",
		telegram: "",
		discord: "",
		link: "https://landverseofficial.com/",
	},
	{
		image: moneyshot,
		imageBig: moneyshotBig,
		tags: ["Hyper Casual Game"],
		title: "Moneyshot",
		description:
			"Tap into MoneyShot: The ultimate Tap2Earn game that's taking Telegram by storm! Practice your throwing skills across themed levels, from tossing cola cans in New York alleys to flipping burgers in fast-food kitchens. Upgrade your abilities, unlock new locations, and watch your earnings soar with every perfect shot or streak. Compete against players worldwide, climb the leaderboards, and build your own MoneyShot empire. With intuitive gameplay and endless opportunities to earn, MoneyShot is your ticket to fun, excitement, and rewards. Ready, aim, earn! ",
		twitter: "https://x.com/moneyshotdotgg",
		telegram: "https://t.me/moneyshotgg",
		discord: "https://discord.com/invite/QpxxpKvhbV",
		link: "https://moneyshot.gg/",
	},
	{
		image: sevian,
		imageBig: sevianBig,
		tags: ["Hyper Casual Game"],
		title: "Seivian",
		description: "Baddest birds on the blockchain.",
		twitter: "https://x.com/Seivians",
		telegram: "",
		discord: "https://discord.com/invite/83WberuWQb",
		link: "https://seivians.space ",
	},
	{
		image: bombpixel,
		imageBig: bombpixelBig,
		tags: ["Casual"],
		title: "BombPixel",
		description:
			"BombPixel, developed by Polrare Labs, is a physics-based blockchain game that offers players an immersive experience focusing on creativity, strategy, and competition.",
		twitter: "https://x.com/bombpixel_xyz ",
		telegram: "https://t.me/bombpixelxyz_discussion",
		discord: "",
		link: "https://bombpixel.xyz/",
	},
	{
		image: fusyfox,
		imageBig: fusyfoxBig,
		tags: ['FPS'],
		title: "FusyFox",
		description: "FusyFox is a multiplayer third-person shooter (TPS) that offers players a stunning visual experience coupled to the likes of AAA gameplay.",
		twitter: "https://x.com/@fusyfox",
		telegram: "https://t.me/FUSYFOXGroup",
		discord: "https://discord.com/invite/fusyfox",
		link: "https://app.fusyfox.com/"
	},
	// {
	// 	image: "",
	// 	imageBig: "",
	// 	tags: ['Idle RPG'],
	// 	title: "The Heroes",
	// 	description: "The Heroes is a leading RPG game on Telegram developed by Ogen Studio, and launched in June 2024. ",
	// 	twitter: "https://x.com/TonHeroes1",
	// 	telegram: "",
	// 	discord: "",
	// 	link: ""
	// }
	{
		image: spacemavericks,
		imageBig: spacemavericksBig,
		tags: ['MOBA'],
		title: "Space Mavericks",
		description: "A real-time web3 game mixing artillery with MOBAs.",
		twitter: "https://x.com/Space_Mavericks",
		telegram: "",
		discord: "",
		link: "https://www.space-mavericks.com/"
	},
	{
		image: gptwars,
		imageBig: gptwarsBig,
		tags: ['FPS'],
		title: "GPT Wars",
		description: "GPT Wars is the first AAA shooter survival game on TON and going to do a full migration to Sei.",
		twitter: "https://x.com/GPTWars",
		telegram: "https://t.me/gptwarschat",
		discord: "",
		link: "https://gptwars.ai/"
	},
	{
		image: worldofdupians,
		imageBig: worldofdupiansBig,
		tags: ['MMORPG'],
		title: "World of Dypian",
		description: "World of Dypians is a revolutionary MMORPG available on Epic Games, set in a connected virtual world, featuring advanced AI, stunning graphics, and immersive gameplay. ",
		twitter: "https://x.com/worldofdypians",
		telegram: "https://t.me/worldofdypians",
		discord: "",
		link: "https://www.worldofdypians.com/"
	},
	{
		image: memepixelquest,
		imageBig: memepixelquestBig,
		tags: ['Strategy'],
		title: "Meme Pixel Quest",
		description: "Meme Pixel Quest combines nostalgic pixel graphics with strategic GameFi mechanics to deliver engaging gameplay and fair rewards.",
		twitter: "https://x.com/memepixelquest",
		telegram: "https://t.me/memepixelquest",
		discord: "",
		link: "memepixel.io "
	},
	{
		image: sunnysideacres,
		imageBig: sunnysideacresBig,
		tags: ['Farm Simulation'],
		title: "Sunnyside Acres",
		description: "A Farming Adventure—your gateway to a open pixelated world teeming with farming, friendships, and thrilling escapades!. The game was created by RYG.Labs and Sigma Team.",
		twitter: "https://x.com/sunnyside_vil",
		telegram: "",
		discord: "",
		link: "https://thesunnyside.cc/"
	}
];
