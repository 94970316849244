import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { Context } from "src";
import { GamePage } from "src/modules/ourGames/ui/GamePage/GamePage";
import { MainPage } from "src/pages/MainPage/MainPage";

export const AppRoutes = observer(() => {
	const { storeGames } = useContext(Context);

	return (
		<Routes>
			<Route path={"/"} element={<MainPage />}></Route>
			{storeGames.currentGame.title !== "" && (
				<Route path={"/game"} element={<GamePage />}></Route>
			)}
		</Routes>
	);
});
