import styles from "./OurGames.module.scss";
import diablo from "src/modules/ourGames/assets/img/png/diablo.png";
import diabloYellow from "src/modules/ourGames/assets/img/png/diabloYellow.png";
import dypians from "src/modules/ourGames/assets/img/png/dypians.png";
import wow from "src/modules/ourGames/assets/img/png/wow.png";
import { Game } from "../Game/Game";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Context } from "src";
import { useNavigate } from "react-router-dom";
import { games } from "../../data/data";
import ScrollAnimation from "react-animate-on-scroll";

export const OurGames = observer(() => {
	const { storeGames } = useContext(Context);

	const navigate = useNavigate();

	return (
		<div className={styles.content} id={"ourGames"}>
			<div className={styles.title}>OUR GAMES</div>
			<div className={styles.cards}>
				{games.map((item, index) => {
					return (
						<ScrollAnimation
							animateIn={styles.animateCards}
							initiallyVisible={true}
						>
							<div
								onClick={() => {
									storeGames.setCurrentGame(item);
									localStorage.setItem("currentGame", JSON.stringify(item));
									navigate("/game");
								}}
								className={styles.game}
							>
								<Game game={item} />
							</div>
						</ScrollAnimation>
					);
				})}
			</div>
			<div className={styles.titleGame}>HOW WE HELP GAME DEVELOPERS</div>
		</div>
	);
});
