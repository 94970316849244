import { GamingEmpire } from "src/modules/gamingEmpire/ui/GamingEmpire/GamingEmpire";
import { Header } from "../../modules/common/ui/Header/Header";
import styles from "./MainPage.module.scss";
import { GamingGuild } from "src/modules/gamingGuild/ui/GamingGuild/GamingGuild";
import { OurGames } from "src/modules/ourGames/ui/OurGames/OurGames";
import { GameDevelopers } from "src/modules/gameDevelopers/ui/GameDevelopers/GameDevelopers";
import { LetsPlay } from "src/modules/letsPlay/ui/LetsPlay/LetsPlay";
import { Footer } from "src/modules/common/ui/Footer/Footer";

export const MainPage = () => {
	return (
		<div className={styles.content}>
			<div className={styles.top}>
				<Header />
				<GamingEmpire />
			</div>
			<GamingGuild />
			<div className={styles.bottom}>
				<OurGames />
			</div>
			<GameDevelopers />
			<LetsPlay />
			<Footer />
		</div>
	);
};
