import styles from "./ButtonJoin.module.scss";
import border from "src/modules/common/assets/img/png/border.png";

export const ButtonJoin = () => {
	return (
		<div
			className={styles.content}
			onClick={() => window.open("https://discord.gg/sei")}
		>
			<img src={border} alt="" className={styles.border} />
			<div className={styles.text}>SEI Guild</div>
		</div>
	);
};
