import { useEffect } from "react";

function useScrollToTop() {
	useEffect(() => {
		document.getElementById("root")?.scrollTo({ top: 0 });
		window.scrollTo({ top: 0 });
	}, []);

	return null;
}

export default useScrollToTop;
