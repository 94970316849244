import { ButtonJoin } from "src/modules/common/ui/ButtonJoin/ButtonJoin";
import styles from "./LetsPlay.module.scss";
import discord from "src/modules/common/assets/img/svg/discord.svg";
import cave from "src/modules/letsPlay/assets/img/png/cave.png";

export const LetsPlay = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<div className={styles.leftBlock}>
					<div className={styles.title}>LET’S PLAY!</div>
					<div className={styles.description}>
						Join Guild to shape the future of Web3 gaming together
					</div>
					<div className={styles.btns}>
						<ButtonJoin />
						<img
							src={discord}
							alt=""
							onClick={() => window.open("https://discord.gg/sei")}
						/>
					</div>
				</div>
				<img src={cave} alt="" className={styles.cave} />
			</div>
		</div>
	);
};
